import React from 'react'
import Layout from '../layouts'

const NotFoundPage = () => (
  <Layout>
    <h1>ページが見つかりませんでした</h1>
    <p>お探しのページは存在しないようです。</p>
  </Layout>
)

export default NotFoundPage
